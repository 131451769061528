<template>
  <!-- 水电抄数详情 -->
  <div class="my_meterReading_detail">
    <van-nav-bar :title="title"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true">
    </van-nav-bar>
    <!-- 保存loading -->
    <van-overlay :show="submitLoading">
      <div style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;"
           class="wrapper"
           @click.stop>
        <van-loading v-if="submitLoading"
                     type="spinner"
                     color="#1989fa"
                     vertical>提交中...</van-loading>
      </div>
    </van-overlay>
    <div class="fixedH"
         style="height: 1.22667rem"></div>
    <div class="query_box">
      <div class="yymm_box">
        <div class="yearMon">{{ `${yearMonth?yearMonth.replace('-',this.$t('schedulingSettings.sesTxt11'))+ this.$t('schedulingSettings.sesTxt12'):''}` }}</div>
      </div>
      <div class="room_box">
        <van-field v-model="dormno"
                   label="宿舍号"
                   label-align="right"
                   colon
                   readonly />
      </div>
    </div>
    <div class="table_box"
         style="overflow: auto;">
      <table ref="table"
             class="table">
        <!-- 表头 -->
        <tr class="top">
          <th v-for="(item_th, index_th) in tableHeadList"
              :key="index_th">
            <div class="title"
                 :style=" item_th.gridwidth  ? { width: item_th.gridwith * 1.5 + 'px' } : 'min-width:90px' ">
              {{ item_th.chnname }}
            </div>
          </th>
          <th>
            <div class="title"
                 style="min-width:40px;">
              拍照
            </div>
          </th>
        </tr>
        <!--展示列表数据 -->
        <tr class="cont"
            v-for="(item_tr, index_tr) in tableData"
            :key="index_tr">
          <td v-for="(item_td, index_td) in tableHeadList"
              :key="index_td">
            <div class="comm_box">
              <div class="boolean_box"
                   v-if="item_td.datatype == 'checkbox'">
                <van-checkbox v-model="item_tr[item_td.fieldname]"></van-checkbox>
              </div>
              <div class="input_box"
                   v-else-if="item_td.datatype == 'num'">
                <van-field v-model="item_tr[item_td.fieldname]"
                           :placeholder="item_td.chnname"
                           @input="changeTotle(item_tr)" />
              </div>
              <div v-else>{{ item_tr[item_td.fieldname] }}</div>
            </div>
          </td>
          <td>
            <div class="upload_box">
              <van-uploader accept="image/*"
                            preview-size="80px"
                            v-model="item_tr.fileList"
                            :max-count="1"
                            :after-read="afterRead"
                            :before-read="beforeRead"
                            @click-upload="clUpload(item_tr,index_tr)"
                            @delete="deleteImg(index_tr)" />
            </div>
          </td>
        </tr>
        <tr class="nodata"
            v-if="tableData.length == 0">
          <td align="center"
              :colspan="tableHeadList.length + 1">
            暂无数据
          </td>
        </tr>
      </table>
    </div>
    <div class="btn_box">
      <div class="btn">
        <van-button type="info"
                    size="small"
                    @click="submitRead">{{$t('module.submit')}}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import * as imageConversion from 'image-conversion'
import { getWxFixDormCost, saveWxFixDormCost } from "@api/wx.js";
export default {
  data () {
    const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
    const from = this.$route.query.from
    const areaData = this.$route.query.areaData
    const floorid = this.$route.query.floorid
    const yymm = this.$route.query.yymm
    const autoid = this.$route.query.itemid
    const dormno = this.$route.query.item
    return {
      from,
      areaData,
      userInfo,
      floorid,
      yymm,
      autoid,
      dormno,
      title: '',
      detail: null,
      yearMonth: '',
      tableHeadList: [],
      tableData: [],
      checkedIdx: 0,
      isLogin: '',
      submitLoading: false
    }
  },
  created () {
    // 保留上一级页面的路由参数回退时回传
    const query = this.$route.query
    this.from = query.from
    this.areaData = query.areaData
    this.floorid = query.floorid
    this.yymm = query.yymm
    this.autoid = query.itemid
    this.dormno = query.item
    this.yearMonth = query.yymm ? query.yymm.substring(0, 4) + '-' + query.yymm.substring(4, 7) : ''
    this.title = this.areaData.replace(/\//g, '>') || ''
    this.getWxFixDormCostData()
  },
  methods: {
    // 上传前处理
    beforeRead (file) {
      let _this = this;
      // console.log(this.fileLists, "压缩前", file)
      if (Array.isArray(file))
      {
        // 一定要异步和for of 否则批量压缩会出错
        return new Promise(async (resolve, reject) => {
          let arr = [];
          let arr2 = [];
          Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: '处理中...'
          });
          for (let item of file)
          {
            if (item.type.indexOf('image') != -1)
            {
              await imageConversion.compressAccurately(item, 500).then(res => {
                res = new File([res], item.name, { type: res.type, lastModified: Date.now() })
                // console.log("多图片上传，压缩后", res)
                arr.push(res)
              })
            } else
            {
              arr2.push(item)
            }
          }
          let arr3 = [...arr, ...arr2]
          Toast.clear();
          resolve(arr3)
        })
      } else
      {
        if (file.type.indexOf('image') != -1)
        {
          Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: '处理中...'
          });
          return new Promise((resolve, reject) => {
            imageConversion.compressAccurately(file, 500).then(res => {
              res = new File([res], file.name, { type: res.type, lastModified: Date.now() })
              // console.log(_this.fileLists, "单图片上传，压缩后", res)
              Toast.clear();
              resolve(res)
            })
          })
        } else
        {
          return new Promise((resolve, reject) => {
            resolve(file)
          })
        }
      }
    },
    // 点击删除时
    deleteImg (idx) {
      this.checkedIdx = idx
      this.tableData[this.checkedIdx].base64str = ''
      this.$forceUpdate()
    },
    // 上传后
    afterRead (file) {
      this.tableData[this.checkedIdx].base64str = file.content
      console.log(this.tableData, 'this.tableData1111111');
      this.$forceUpdate()
    },
    // 点击上传的时候
    clUpload (item, idx) {
      this.checkedIdx = idx
      console.log(this.checkedIdx, 'this.checkedIdx');
    },
    // 提交保存
    submitRead () {
      this.submitLoading = true
      console.log(this.tableData, 'this.tableData222222');
      let ary = this.tableData.map(it => {
        let obj = {
          mtype: it.mtype,
          num2: it.num2,
          base64str: it.base64str
        }
        return obj
      })
      let param = {
        autoid: this.autoid,
        username: this.userInfo.username,
        data: ary
      }
      console.log(param, 'param');
      saveWxFixDormCost(param).then(res => {
        this.submitLoading = false
        if (res.data && res.data[0].info)
        {
          Toast(res.data[0].info)
        } else
        {
          Toast.success(this.$t('module.submitScu'))
          this.getWxFixDormCostData()
        }
      })
    },
    // 输入月末时计算合计
    changeTotle (it) {
      it.totalnum = Number(it.num2) - Number(it.num1)
    },
    // 获取指定月份某个宿舍的抄数记录
    getWxFixDormCostData () {
      getWxFixDormCost({
        autoid: this.autoid,
        username: this.userInfo.username
      }).then(res => {
        this.tableHeadList = res.fieldlist.filter(it => { return it.gridshow == 1 || it.gridshow == true })
        this.tableHeadList.forEach(el => {
          if (el.fieldname == 'num2')
          {
            el.datatype = 'num'
          } else
          {
            el.datatype = 'str'
          }
        });
        this.tableData = res.data
        this.tableData.forEach(el => {
          el.base64str = null
          el.datatype = 'img'
          el.fileList = el.pic_url ? [{ url: el.pic_url }] : []
        });
      })
    },
    // 返回
    onClickLeft () {
      this.$router.push({
        path: '/meterReading', query: { from: this.from, areaData: this.areaData, floorid: this.floorid, yymm: this.yymm }
      })
    }
  }

}
</script>

<style lang="less" scoped>
.my_meterReading_detail {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
  .van-nav-bar {
    background: #2b8df0;
    z-index: 99;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .query_box {
    height: 120px;
    padding: 20px;
    display: flex;
    background: #ffffff;
    .room_box {
      width: 50%;
      display: flex;
      align-items: center;
      border-left: 2px solid;
      .van-cell {
        font-size: 32px;
        padding: 10px 20px;
        /deep/.van-cell__title {
          font-weight: bold;
          color: #333333;
        }
        /deep/.van-cell__value {
          .van-field__body {
            .van-field__control {
            }
          }
        }
      }
      .van-cell::after {
        border: unset;
      }
    }
    .yymm_box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      .yearMon {
        margin: 0 10px;
        font-size: 32px;
        font-weight: bold;
        text-align: center;
      }
    }
  }
  .table_box {
    margin-top: 40px;
    height: calc(100% - 160px - 1.22667rem - 110px);
    .table {
      width: 100%;
      border-spacing: 0px;
      tr {
        td:nth-child(1) {
          border-left: 2px solid #dcdfe6;
        }
        th,
        td {
          padding: 6px 10px;
          text-align: center;
          border-bottom: 2px solid #dcdfe6;
          border-right: 2px solid #dcdfe6;
          .comm_box {
            display: flex;
            justify-content: center;
            .boolean_box {
              .van-checkbox {
                justify-content: center;

                /deep/.van-checkbox__icon--checked {
                  .van-icon {
                    color: #fff !important;
                    background-color: #1989fa !important;
                    border-color: #1989fa !important;
                  }
                }

                /deep/.van-checkbox__icon--disabled .van-icon {
                  background-color: #fff;
                }
              }
            }
            .input_box {
              width: 150px;
              .van-cell {
                font-size: 24px;
                border: 2px solid #d2d2d2;
                border-radius: 10px;
                padding: 8px 16px;
              }
            }
          }
        }
      }
      .top {
        position: sticky;
        top: 0;
        font-size: 28px;
        background: #e9f3fd;
        height: 64px;
        th:nth-child(1) {
          border-left: 2px solid #dcdfe6;
        }
        th {
          border-top: 2px solid #dcdfe6;
        }
      }
      .cont {
        height: 64px;
        font-size: 24px;
        .upload_box {
          display: flex;
          align-items: center;
          justify-content: center;
          /deep/.van-uploader {
            .van-uploader__wrapper {
              .van-uploader__preview {
                margin: 0;
              }
            }
          }
        }
      }
      .cont:nth-child(2n-1) {
        background: #f5f5f5;
      }
      .nodata {
        td {
          font-size: 28px;
        }
      }
    }
  }
  .btn_box {
    height: 110px;
    display: flex;
    align-items: center;
    padding: 20px;
    background: #ffffff;
    .btn {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex: 1;
      .van-button {
        width: 60%; // 40%;
        font-size: 32px;
        padding: 10px 0;
        border-radius: 20px;
      }
    }
  }
}
</style>